import React, { useState, useRef } from "react";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import Phone from "../component/Phone";
import emailjs from "@emailjs/browser";

function Contact() {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_gh7ck0g", "template_cice8fl", form.current, {
        publicKey: "7bwsohcrkchv3m5_G",
      })
      .then(
        () => {
          form.current.reset();
          setShowConfirmation(true);
          setTimeout(() => {
            setShowConfirmation(false);
          }, 3000);
        },
        (error) => {
          console.log("FAILED...", "email did not send");
        }
      );
  };

  return (
    <div className="bg-gray-200 min-h-screen">
      <NavBar />
      <Phone />

      <div className="relative mx-auto py-12 md:py-24 bg-gray-100 ">
        <h1 className="text-2xl md:text-4xl font-bold text-center mb-6 md:mb-8">
          Let's Chat
        </h1>

        <form
          className=" flex flex-col items-center justify-center"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="mb-4 w-96 ">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-2 "
            >
              Your Name
            </label>
            <input
              type="text"
              id="name"
              name="from_name"
              className="input-field rounded-lg w-full "
              placeholder="Your name here..."
              required
            />
          </div>
          <div className="mb-4 w-96">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Your Email
            </label>
            <input
              type="email"
              id="email"
              name="user_email"
              className="input-field rounded-lg w-full"
              placeholder="Your email address here..."
              required
            />
          </div>
          <div className="mb-4 w-96">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Message
            </label>
            <textarea
              id="message"
              rows="4"
              name="message"
              className="input-field rounded-lg w-full"
              placeholder="Leave a comment..."
              required
            ></textarea>
          </div>
          <div className="w-full flex justify-center">
            <button
              type="submit"
              className="w-40 submit-button bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
          {showConfirmation && (
            <div className="confirmation mt-2 text-green-600">
              Email sent successfully!
            </div>
          )}
        </form>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
