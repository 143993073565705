import React from "react";

const Welcome = () => {
  return (
    <div className="flex flex-col justify-center items-center bg-gray-100 text-green text-center px-4 py-8 sm:py-12 md:py-16 lg:py-20">
      <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-medium mb-4">
        Welcome to Blissful Days Psychiatric Services – Your Path to Mental
        Wellness
      </h1>
      <p className="text-base sm:text-lg md:text-xl lg:text-1xl px-4 sm:px-8 md:px-12 lg:px-20">
        At Blissful Days Psychiatric Services, we believe in fostering mental
        wellness through compassionate care and evidence-based treatment. Our
        telehealth-based practice is dedicated to providing high-quality
        psychiatric services, including evaluations and medication management,
        for individuals aged 18 and above in Maryland, Washington D.C., and
        Virginia.
      </p>
    </div>
  );
};

export default Welcome;
