import React from "react";
import NavBar from "component/NavBar";
import Footer from "component/Footer";
import { anxiety1 } from "assets";

const Anxiety = () => {
  return (
    <>
      <NavBar />
      <div className="relative">
        <img
          className="w-full h-48 sm:h-64 md:h-80 lg:h-96 object-cover mt-5"
          src={anxiety1}
          alt="anxiety"
        />
        <div className=" inset-0 flex items-center justify-center transform -translate-y-20 md:-translate-y-32 lg:-translate-y-40">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold">
            Anxiety
          </h1>
        </div>
        <div className="flex items-center justify-center mt-6 sm:mt-8 md:mt-10">
          <p className="text-2xl sm:text-3xl md:text-4xl font-bold underline decoration-green-500 decoration-4 underline-offset-8 text-center">
            <strong>Managing irrational worries and fears</strong>
          </p>
        </div>
        <div className="flex items-center justify-center mt-4 sm:mt-6 md:mt-8 px-4 sm:px-16 md:px-32">
          <p className="text-base sm:text-lg md:text-xl text-center">
            Are you grappling with enduring worries and fears? Be assured that
            we stand ready to provide comprehensive support throughout your
            journey. Employing our individualized treatment methodology, we
            systematically explore the underlying causes of your anxiety,
            meticulously analyzing your responses to triggers and their
            influence on your overall well-being. Our objective is to furnish
            you with adaptive coping mechanisms conducive to a healthier mental
            state. It is crucial to recognize that overcoming anxiety is a
            process rather than a swift resolution. With our steadfast
            commitment and your proactive engagement, we can collaboratively
            strive to proficiently manage symptoms and navigate the complexities
            of challenging thoughts.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Anxiety;
