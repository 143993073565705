import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Services from "./component/Services";
import AboutMe from "./pages/AboutMe";
import ADHD from "./pages/ADHD";
import Anxiety from "./pages/Anxiety";
import BipolarDisorder from "./pages/BipolarDisorder";
import Depression from "./pages/Depression";
import MainServicePage from "pages/MainServicePage";
import FAQ from "pages/FAQ";

function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/adhd" element={<ADHD />} />
            <Route path="/anxiety" element={<Anxiety />} />
            <Route path="/bipolardisorder" element={<BipolarDisorder />} />
            <Route path="/depression" element={<Depression />} />
            <Route path="/mainservicepage" element={<MainServicePage />} />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
