import React from "react";
import NavBar from "component/NavBar";
import Footer from "component/Footer";
import Partner from "component/Partner";
import Phone from "component/Phone";
import ImageSlider from "component/ImageSlider";
import Services from "component/Services";
import Welcome from "component/Welcome";
import Contact from "../pages/Contact";

const Home = () => {
  return (
    <>
      <Phone />
      <NavBar />
      <ImageSlider />
      <Welcome />
      <Services />
      <Partner />
      <Footer />
    </>
  );
};

export default Home;
