import React from "react";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import { Bipolardisorder } from "assets";

const BipolarDisorder = () => {
  return (
    <>
      <NavBar />
      <div className="relative">
        <img
          className="w-full h-48 sm:h-64 md:h-80 lg:h-96 object-cover mt-5"
          src={Bipolardisorder}
          alt="Bipolar Disorder"
        />
        <div className="inset-0 flex items-center justify-center transform -translate-y-24 md:-translate-y-40">
          <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center">
            Bipolar Disorder
          </h1>
        </div>
        <div className="flex items-center justify-center mt-6 sm:mt-8 md:mt-10 px-4 sm:px-8 md:px-16">
          <p className="text-xl sm:text-2xl md:text-3xl font-bold text-center underline decoration-green-500 decoration-4 underline-offset-8">
            <strong>
              Medications and therapy to address symptoms and other issues
            </strong>
          </p>
        </div>
        <div className="flex items-center justify-center mt-4 sm:mt-6 md:mt-8 px-4 sm:px-8 md:px-16">
          <p className="text-base sm:text-lg md:text-xl text-center">
            Bipolar Disorder presents itself through pronounced shifts in an
            individual’s energy levels, moods, behaviors, and other multifaceted
            dimensions. These fluctuations can significantly disrupt well-being
            and hinder optimal functioning, thereby influencing the overall
            quality of life. The conventional strategy for addressing bipolar
            disorder typically encompasses a synergistic approach involving
            psychotherapy and medication. Psychotherapy offers a structured
            platform for individuals to articulate their experiences and
            confront the challenges associated with the disorder, while
            medication is employed to regulate hormones and modulate brain
            activity, particularly those linked to mood swings, temperament
            variations, and energy levels.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BipolarDisorder;
