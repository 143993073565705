import React, { useState } from "react";
import { blissfuldays } from "../assets/";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white text-black fixed w-full z-20 top-0 border-b border-custom-green">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <div className="flex items-center space-x-4">
            <Link to="/">
              <img
                className="h-14 w-auto hover:fb-scale-150"
                src={blissfuldays}
                alt="Logo"
              />
            </Link>
          </div>
          {/* Mobile Menu Toggle Button */}
          <div className="md:hidden block">
            <button
              onClick={toggleMenu}
              className="block text-black focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
          {/* Desktop Menu */}
          <div className="hidden md:flex md:items-center md:justify-center md:space-x-4 flex-grow">
            <ul className="flex space-x-16">
              <li>
                <Link to="/" className="hover:text-blue-700">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:text-blue-700">
                  About
                </Link>
              </li>
              <li>
                <Link to="/MainServicePage" className="hover:text-blue-700">
                  Services
                </Link>
              </li>
              <li>
                <Link to="/faq" className="hover:text-blue-700">
                  FAQ's
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-blue-700">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Mobile Menu Links */}
        {isOpen && (
          <div className="md:hidden block">
            <div className="flex flex-col space-y-4">
              <Link to="/" className="hover:text-blue-700">
                Home
              </Link>
              <Link to="/about" className="hover:text-blue-700">
                About
              </Link>
              <Link to="/MainServicePage" className="hover:text-blue-700">
                Services
              </Link>
              <Link to="/faq" className="hover:text-blue-700">
                FAQ's
              </Link>
              <Link to="/contact" className="hover:text-blue-700">
                Contact
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
