import React from "react";
import { adhd, Carousel2, anxiety, Bipolardisorder } from "../assets";
import { Link } from "react-router-dom";
import "../index.css";

const CarouselItems = [
  {
    src: Carousel2,
    alt: "Carousel 1",
    title: "Depression",
    description: "Addressing your depression symptoms and their causes.",
    link: "/depression", // URL for the Depression page
  },
  {
    src: anxiety,
    alt: "Carousel 2",
    title: "Anxiety",
    description: "Healthy coping mechanisms for irrational worries.",
    link: "/anxiety", // URL for the Anxiety page
  },
  {
    src: adhd,
    alt: "adhd",
    title: "ADHD",
    description: "Managing hyperactivity and Attention deficit.",
    link: "/adhd", // URL for the ADHD page
  },
  {
    src: Bipolardisorder,
    alt: "Carousel 4",
    title: "Bipolar Disorder",
    description: "Treatments for fluctuating moods and energy levels.",
    link: "/bipolardisorder",
  },
];

const CarouselCard = ({ src, alt, title, description, link }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 transform transition-transform duration-500 hover:scale-105 ">
    <img
      className="rounded-t-lg w-full h-48 sm:h-56 md:h-64 lg:h-80 object-cover"
      src={src}
      alt={alt}
    />
    <div className="p-5 transition-colors duration-300 hover:bg-[#b2d235]">
      <h5 className="mb-2 text-xl sm:text-2xl md:text-3xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
        {title}
      </h5>
      <p className="mb-3 text-sm sm:text-base md:text-lg font-normal text-gray-700 dark:text-gray-400 text-center">
        {description}
      </p>
      <div className="flex justify-center">
        <Link to={link}>
          <button className="mt-3 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg transition duration-500 ease-in-out  hover:bg-green-600 transform hover:-translate-y-1 hover:scale-110 ...">
            Read More
          </button>
        </Link>
      </div>
    </div>
  </div>
);

const Services = () => (
  <div className="container mx-auto px-5 rounded-lg ">
    <h2 className="text-2xl sm:text-3xl md:text-4xl font-normal text-center mb-6 mt-11">
      Our Services
    </h2>
    <p className=" sm:text-xl md:text-2xl font-light text-center mb-8">
      Empowering minds, restoring hope: Our mental health services are anchored
      in compassion, expertise, and a commitment to your well-being.
    </p>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
      {CarouselItems.map((item, index) => (
        <CarouselCard
          key={index}
          src={item.src}
          alt={item.alt}
          title={item.title}
          description={item.description}
          link={item.link}
        />
      ))}
    </div>
  </div>
);

export default Services;
