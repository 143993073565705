// import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faClock,
//   faPhone,
//   faFax,
//   faEnvelope,
//   faLocation,
// } from "@fortawesome/free-solid-svg-icons";

// const Phone = () => {
//   return (
//     <div className="mt-20 flex sm:flex-col-1  md:grid-cols-5 lg:flex-row   justify-center items-center  bg-gray-400 p-2">
//       <div>
//         <FontAwesomeIcon icon={faPhone} className="mr-2" beatFade />
//         <span className="text-green-900 font-semibold">(240) 610-3766</span>
//       </div>
//       <span className="mx-4"></span> {/* Added a separator with spacing */}
//       <div>
//         <FontAwesomeIcon icon={faEnvelope} className="mr-2" beatFade />
//         <span className="text-green-900 font-semibold">
//           info@blissfuldayspsychiatry.com
//         </span>
//       </div>
//       <span className="mx-4"></span> {/* Added a separator with spacing */}
//       <div>
//         <FontAwesomeIcon icon={faLocation} className="mr-2" beatFade />
//         <span className="text-green-900 font-semibold">
//           12530 Fairwood Parkway Suite 102, Bowie MD 20720 (virtual)
//         </span>
//       </div>
//       <span className="mx-4"></span> {/* Added a separator with spacing */}
//       <div>
//         <FontAwesomeIcon icon={faFax} className="mr-2" beatFade />
//         <span className="text-green-900 font-semibold">
//           (301) 547 7476 | (301) 238-8019
//         </span>
//       </div>
//       <span className="mx-4"></span> {/* Added a separator with spacing */}
//       <div>
//         {" "}
//         <FontAwesomeIcon icon={faClock} className="mr-2" beatFade />
//         <span className="text-green-900 font-semibold">
//           Mondays to Friday 9am-5pm
//         </span>
//       </div>
//     </div>
//   );
// };

// export default Phone;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPhone,
  faFax,
  faEnvelope,
  faLocation,
} from "@fortawesome/free-solid-svg-icons";

const Phone = () => {
  return (
    <div className="mt-5 flex flex-col sm:flex-col md:flex-row justify-center items-center bg-gray-400 p-4 space-y-4 md:space-y-0 md:space-x-8">
      <div className="flex items-center">
        <FontAwesomeIcon icon={faPhone} className="mr-2" beatFade />
        <span className="text-green-900 font-semibold">(240) 610-3766</span>
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" beatFade />
        <span className="text-green-900 font-semibold">
          info@blissfuldayspsychiatry.com
        </span>
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faLocation} className="mr-2" beatFade />
        <span className="text-green-900 font-semibold">
          12530 Fairwood Parkway Suite 102, Bowie MD 20720 (virtual)
        </span>
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faFax} className="mr-2" beatFade />
        <span className="text-green-900 font-semibold">
          (301) 547 7476 | (301) 238-8019
        </span>
      </div>

      <div className="flex items-center">
        <FontAwesomeIcon icon={faClock} className="mr-2" beatFade />
        <span className="text-green-900 font-semibold">
          Mondays to Friday 9am-5pm
        </span>
      </div>
    </div>
  );
};

export default Phone;
