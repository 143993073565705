import React from "react";
import NavBar from "component/NavBar";
import Footer from "component/Footer";
import { about, aboutme, services } from "../assets";
import Phone from "component/Phone";

const AboutMe = () => {
  return (
    <div>
      <NavBar />
      <Phone />

      {/* <div className=" mt-0 object-cover bg-green-500 ">
        <img src={about} alt="about" />
      </div> */}

      <div className=" w-auto h-auto bg-red-500 flex items-center justify-center">
        <img src={about} alt="about" className="w-full h-svh object-cover" />
      </div>

      <div className="text-center py-8 px-20 bg-gray-200">
        <p className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 underline decoration-green-500 decoration-4 underline-offset-8">
          <strong>About Blissful Days</strong>
        </p>
        <p className=" sm:text-lg md:text-xl lg:text-1xl  mx-auto">
          Blissful Days Psychiatric Services is a telehealth-based practice
          committed to providing high quality accessible mental health services.
          Our team of experienced professionals believe in creating a safe and
          nonjudgmental space where individuals can freely express their
          thoughts, feelings and be heard. Through evidence-based practices and
          holistic approach, we provide personalized treatment plans tailored to
          each client’s specific needs. We strive to empower our clients by
          instilling a sense of control over their condition and aim at
          contributing to a healthier and more fulfilling life. We are devoted
          to creating a trusting and empathetic environment where individuals
          feel secure on their path to mental wellness.
        </p>
      </div>

      {/* Mission Statement Section */}
      <section className="bg-gray-300 py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
            {/* Image Section */}
            <div className="lg:col-span-5 flex justify-center lg:justify-start ">
              <img
                src={services}
                alt="aboutme"
                className="card-zoom-image rounded-lg hover:scale-110 transition duration-300 transform lg:-translate-x-2"
              />
            </div>

            {/* Text Section */}
            <div className="lg:col-span-7 flex flex-col justify-center items-start animate-slide-in-right">
              <h1 className="text-3xl md:text-5xl xl:text-6xl font-mono mt-4 lg:mb-0 text-green-500">
                Mission statement
              </h1>
              <p className="text-base md:text-lg lg:text-xl font-light dark:text-gray-400 mt-6">
                Our mission is to improve our clients' quality of life by
                providing accessible, safe, and evidence-based mental
                healthcare.
                <br />
                <br />
                <span className="font-bold text-2xl md:text-3xl xl:text-4xl">
                  Our Values
                </span>
                <br />
                <br />
                <span className="font-medium">Client first:</span> The client is
                our top priority in everything we do.
                <br />
                <br />
                <span className="font-medium">Integrity:</span> We uphold the
                highest standards of integrity in our interactions, ensuring
                honesty and ethical conduct in all our endeavors.
                <br />
                <br />
                <span className="font-medium">Respect:</span> We value and
                respect the unique experiences, perspectives, and needs of each
                client we serve.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Our Providers Section */}
      <section className="bg-green-50 py-8 lg:py-4">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            {/* Text Section */}
            <div className="lg:col-span-7 flex flex-col justify-center items-center lg:items-start">
              <h1 className="text-3xl md:text-5xl xl:text-6xl font-mono text-center lg:text-left mt-4 text-green-500">
                Our Providers
              </h1>
              <p className="text-base md:text-lg lg:text-xl font-light dark:text-gray-400 mt-6 text-center lg:text-left">
                <span className="font-bold text-2xl md:text-3xl xl:text-4xl">
                  Nneka Wogu, MSN, CRNP-PMH
                </span>{" "}
                <br /> <br />
                Nneka Wogu is an experienced board-certified psychiatric nurse
                practitioner. She has worked in various settings including
                hospitals, outpatient mental health clinics, and addiction
                centers. Nneka works mostly with adults exhibiting behavioral
                health symptoms. She specializes in treating anxiety,
                depression, ADHD, bipolar disorder, PTSD, OCD, addiction, and
                other psychiatric illnesses. Nneka is passionate about helping
                individuals improve their mental health and well-being. She
                values the therapeutic relationship with her patients by taking
                time to actively listen, empathize, and build trust.
              </p>
            </div>

            {/* Image Section */}
            <div className="lg:col-span-5 flex justify-center lg:justify-end mt-8 ">
              <img
                src={aboutme}
                alt="aboutme"
                className="card-zoom-image rounded-lg hover:scale-110 transition duration-300"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default AboutMe;
