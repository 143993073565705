import React from "react";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import { adhd1 } from "assets";

const ADHD = () => {
  return (
    <>
      <NavBar />
      {/* <div className="relative  bg-red-400">
        <img
          className="w-full h-auto sm:h-80 md:h-96 object-cover mt-20"
          src={adhd1}
          alt="adhd"
        />

        <div className="absolute inset-0 flex items-center justify-center transform -translate-y-24 md:-translate-y-40">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold">
            ADHD
          </h1>
        </div>

        <div className="flex items-center justify-center mt-6 sm:mt-8 md:mt-10 px-4">
          <p className="text-2xl sm:text-3xl md:text-4xl font-bold text-center underline decoration-green-500 decoration-4 underline-offset-8">
            <strong>
              Treatment services for hyperactivity and inattention
            </strong>
          </p>
        </div>

        <div className="flex items-center justify-center mt-4 sm:mt-6 md:mt-8 px-4 sm:px-16 md:px-32">
          <p className="text-base sm:text-lg md:text-xl text-center">
            Attention Deficit Hyperactivity Disorder (ADHD), commonly associated
            with children, can also manifest in adults who may have remained
            undiagnosed during their formative years. The symptoms frequently
            involve hyperactivity, inattention, or a combination of both. The
            therapeutic approach to ADHD is centered on a comprehensive
            understanding of the individual’s condition, coupled with targeted
            interventions to improve behavioral management and enhance focus for
            task completion.
          </p>
        </div>
      </div> */}

      <div className="relative ">
        <img
          // className="w-full h-96 object-cover  "
          className="w-full  sm:h-64 md:h-80 lg:h-96 object-cover mt-5"
          src={adhd1}
          alt="adhd"
        />
      </div>

      <div className=" inset-0 flex items-center justify-center transform -translate-y-24 md:-translate-y-40">
        <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold">
          ADHD
        </h1>
      </div>

      <div className="flex items-center justify-center mt-6 sm:mt-8 md:mt-10 px-4">
        <p className="text-2xl sm:text-3xl md:text-4xl font-bold text-center underline decoration-green-500 decoration-4 underline-offset-8">
          <strong>Treatment services for hyperactivity and inattention</strong>
        </p>
      </div>

      <div className="flex items-center justify-center mt-4 sm:mt-6 md:mt-8 px-4 sm:px-16 md:px-32">
        <p className="text-base sm:text-lg md:text-xl text-center">
          Attention Deficit Hyperactivity Disorder (ADHD), commonly associated
          with children, can also manifest in adults who may have remained
          undiagnosed during their formative years. The symptoms frequently
          involve hyperactivity, inattention, or a combination of both. The
          therapeutic approach to ADHD is centered on a comprehensive
          understanding of the individual’s condition, coupled with targeted
          interventions to improve behavioral management and enhance focus for
          task completion.
        </p>
      </div>
      {/* </div> */}

      <Footer />
    </>
  );
};

export default ADHD;
