import { useState, useEffect } from "react";
import { Carousel1, affection, Carousel4 } from "../assets";

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    { image: Carousel4, text: "Commited to your mental wellness " },
    { image: affection, text: "Explore Our Services" },
    { image: Carousel1, text: "Telehealth Convenience" },
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(handleNext, 9000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative  overflow-hidden max-w-full mt-0">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div className="relative min-w-full" key={index}>
            <img
              src={slide.image}
              alt={`Slide ${index + 1}`}
              className="w-full h-dvh object-cover"
            />
            <div className="absolute inset-0 flex items-center justify-start p-4 bg-black bg-opacity-40">
              <p
                className="text-green-200 text-2xl font-bold bg-opacity-50 p-4 rounded-lg transform translate-x-[-100%] opacity-0 transition-all duration-1000 ease-in-out delay-200"
                style={{
                  transform: `translateX(${
                    currentIndex === index ? "0" : "-100%"
                  })`,
                  opacity: `${currentIndex === index ? "1" : "0"}`,
                }}
              >
                {slide.text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlider;
