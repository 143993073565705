import React from "react";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import Phone from "../component/Phone";
import { faq } from "../assets";

const FAQ = () => {
  return (
    <div>
      <NavBar />
      <div className="relative">
        <Phone />
        <img className="w-full mb-2 object-cover h-96" src={faq} alt="faq" />
      </div>
      <div className="flex flex-col items-center  px-4 py-8">
        <p className="text-center text-2xl sm:text-3xl md:text-4xl font-bold mb-4 underline decoration-green-500 decoration-4 underline-offset-8">
          <strong>FAQ</strong>
        </p>
        <p className="text-center">
          1. How Soon Will Someone Contact Me? <br />
          We will contact you within 24 hours.
          <br /> <br />
          2. Do You Work With Insurance? What If I Don't Have Insurance? <br />
          Blissful Days accepts various insurances; contact us for verification.
          If uninsured, inquire about affordable self-pay rates and flexible
          payment options.
          <br /> <br />
          3. What If I Have More Questions? <br />
          Feel free to reach out to our dedicated team at Blissful Days
          Psychiatric Services for any additional questions. We’re here to
          provide support and address your inquiries promptly.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
