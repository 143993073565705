import React, { useEffect } from "react";
import { psychology } from "../assets";

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://member.psychologytoday.com/verified-seal.js";
    script.type = "text/javascript";
    script.setAttribute("data-badge", "13");
    script.setAttribute("data-id", "1231924");
    script.setAttribute(
      "data-code",
      "aHR0cHM6Ly93d3cucHN5Y2hvbG9neXRvZGF5LmNvbS9hcGkvdmVyaWZpZWQtc2VhbC9zZWFscy9bQkFER0VdL3Byb2ZpbGUvW1BST0ZJTEVfSURdP2NhbGxiYWNrPXN4Y2FsbGJhY2s="
    );
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <footer className="bg-dark-100">
      <div className="mx-auto max-w-screen-xl p-4 py-6 lg:py-8 flex flex-col items-center justify-center">
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © {getCurrentYear()}. All Rights Reserved. blissfuldayspsych
          </span>
          <a
            href="https://www.psychologytoday.com/profile/1231924"
            className="sx-verified-seal"
          >
            <button className="px-20 py-1 rounded mt-4 sm:mt-0">
              <img
                src={psychology}
                alt="Psychology Today"
                className="max-w-[200px]"
              />
            </button>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
