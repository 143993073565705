import React, { useEffect } from "react";

function Zocdoc() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://offsiteschedule.zocdoc.com/plugin/embed";

    document.body.appendChild(script);
  }, []);

  return (
    <div>
      <a
        style={{ display: "block" }}
        href="https://www.zocdoc.com/practice/blissful-days-psychiatric-services-llc-99514?lock=true&isNewPatient=false&referrerType=widget"
        className="zd-plugin"
        data-type="book-button"
        data-practice-id="99514"
        title="Blissful Days Psychiatric Services, LLC"
      >
        <img
          src="https://offsiteSchedule.zocdoc.com/images/remote/rb_BookOnline_White_162x71.png?type=bobjs&monolith_provider_id=99514&practice_id=pt_dvzeMGTzH06B_5v7_cD0Mh"
          alt="Blissful Days Psychiatric Services, LLC"
          title="Blissful Days Psychiatric Services, LLC"
          style={{ border: 0 }}
        />
      </a>
    </div>
  );
}

export default Zocdoc;
