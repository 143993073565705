import React from "react";
import NavBar from "component/NavBar";
import Footer from "component/Footer";
import { moodswing } from "assets";

const Depression = () => {
  return (
    <>
      <NavBar />
      <div className="relative">
        <img
          className="w-full h-48 sm:h-64 md:h-80 lg:h-96 object-cover mt-5"
          src={moodswing}
          alt="moodswing"
        />
        <div className=" flex inset-0  items-center justify-center transform -translate-y-16 sm:-translate-y-24 md:-translate-y-32 lg:-translate-y-40 ">
          <h1 className="text-white text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center">
            Depression
          </h1>
        </div>
        <div className="flex flex-col items-center mt-6 sm:mt-8 md:mt-10 px-4 md:px-8 lg:px-16">
          <p className="text-2xl sm:text-3xl md:text-4xl font-bold text-center underline decoration-green-500 decoration-4 underline-offset-8">
            <strong>Addressing causes and symptoms of depression</strong>
          </p>
        </div>
        <div className="flex flex-col items-center mt-6 sm:mt-8 md:mt-10 px-4 md:px-8 lg:px-16">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl text-center">
            Depression, classified as a behavioral health disorder, manifests
            through a diverse array of symptoms, challenging the misconception
            that it is exclusively characterized by feelings of loneliness or
            sadness. While these emotions may be prevalent in certain cases,
            they do not comprehensively define the complexity of depression. It
            is conceivable for individuals to outwardly project contentment
            while quietly grappling with depression. If you are contending with
            emotions of despair, hopelessness, or profound grief, we are at your
            disposal to provide support. Our services are meticulously tailored
            to your specific needs, concentrating on the identification of the
            root causes of your depression and the development of effective
            strategies to manage it in a constructive manner.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Depression;
