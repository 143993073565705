import React from "react";
import { aetna, cigna, carefirst, optum, united } from "../assets";

const Insurance = [
  { src: aetna, alt: "Aetna" },
  { src: cigna, alt: "CIGNA" },
  { src: carefirst, alt: "CArefirst" },
  { src: optum, alt: "Optum" },
  { src: united, alt: "United" },
  // { src: java, alt: "Java" },
  // { src: devops, alt: "DevOps" },
  // { src: postgres, alt: "PostgreSQL" },
  // { src: mysql, alt: "MySQL" },
  // { src: mongodb, alt: "MongoDB" },
  // { src: bash, alt: "Bash" },
  // { src: github, alt: "GitHub" },
  // { src: jenkins, alt: "Jenkins" },
  // { src: nmap, alt: "Nmap" },
  // { src: wireshark, alt: "Wireshark" },
  // { src: oracle, alt: "Oracle" },
  // { src: docker, alt: "Docker" },
  // { src: nginx, alt: "NGINX" },
  // { src: caddy, alt: "Caddy" },
  // { src: grafana, alt: "Grafana" },
  // { src: kubernetes, alt: "Kubernetes" },
  // { src: gitlab, alt: "GitLab" },
  // { src: bitbucket, alt: "Bitbucket" },
];

function Partner() {
  return (
    <div className="mt-16 ">
      <div class="mb-24">
        <h1 className="mt-4 text-center text-3xl md:text-4xl font-normal">
          Insurance
        </h1>
        <p className="flex justify-center items-center text-center mb-8 md:text-2xl font-light  ">
          We accept most insurance providers including medicaid and medicare.
          Contact us if you have any questions regarding your coverage.
        </p>
      </div>
      <div className="bodyP ">
        <div className="inline-flex w-full flex-nowrap overflow-hidden bg-white [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
          <ul className="brands-wrapper">
            {Insurance.map((tech, index) => (
              <li key={index}>
                <img src={tech.src} width="250" height="100" alt={tech.alt} />
              </li>
            ))}
          </ul>
          <ul className="brands-wrapper" aria-hidden="true">
            {Insurance.map((tech, index) => (
              <li key={index}>
                <img src={tech.src} width="250" height="100" alt={tech.alt} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Partner;
