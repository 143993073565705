import React from "react";
import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import Partner from "../component/Partner";
import {
  adhd,
  Carousel2,
  anxiety,
  Bipolardisorder,
  services,
  adhd1,
} from "../assets";
import { Link } from "react-router-dom";

const CarouselItems = [
  {
    src: Carousel2,
    alt: "Carousel 1",
    title: "Depression",
    description: "Addressing your depression symptoms and their causes.",
    link: "/depression",
  },
  {
    src: anxiety,
    alt: "Carousel 2",
    title: "Anxiety",
    description: "Healthy coping mechanisms for irrational worries.",
    link: "/anxiety",
  },
  {
    src: adhd,
    alt: "ADHD",
    title: "ADHD",
    description: "Managing hyperactivity and Attention deficit.",
    link: "/adhd",
  },
  {
    src: Bipolardisorder,
    alt: "Bipolar Disorder",
    title: "Bipolar Disorder",
    description: "Treatments for fluctuating moods and energy levels.",
    link: "/bipolardisorder",
  },
];

const servicesList = [
  "Anxiety",
  "Depression",
  "Attention Deficit/Hyperactivity Disorder (ADHD)",
  "Bipolar Disorder",
  "Panic disorder/ Panic attack",
  "Geriatric behavioral health",
  "Obsessive Compulsive Disorder (OCD)",
  "Sleep Disorder",
  "Substance abuse (suboxone treatment)",
  "Dual diagnosis",
  "Trauma/PTSD",
  "Postpartum Depression",
  "Psychosis and more",
];

const CarouselCard = ({ src, alt, title, description, link }) => (
  <div className="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 transform transition-transform duration-500 hover:scale-105">
    <img
      className="rounded-t-lg w-full h-48 object-cover"
      src={src}
      alt={alt}
    />
    <div className="p-5 transition-colors duration-300 hover:bg-[#2f9b6c]">
      <h5 className="mb-2 text-lg sm:text-xl md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center">
        {title}
      </h5>
      <p className="mb-3 text-sm sm:text-base md:text-lg font-normal text-gray-700 dark:text-gray-400 text-center">
        {description}
      </p>
      <div className="flex justify-center">
        <Link to={link}>
          <button className="mt-3 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition-colors duration-300">
            Read More
          </button>
        </Link>
      </div>
    </div>
  </div>
);

const Anxiety = () => {
  return (
    <>
      <NavBar />

      <div className="relative">
        <img
          className="w-full h-60 sm:h-80 md:h-96 object-cover mt-5"
          src={services}
          alt="anxiety"
        />
        <div className="absolute inset-0 flex items-center justify-center transform -translate-y-1/2">
          <h1 className="text-white text-3xl sm:text-4xl md:text-5xl font-bold mt-60">
            Services we Offer
          </h1>
        </div>
      </div>
      <div className="text-center py-8 px-4 bg-green-100">
        <p className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 underline decoration-green-500 decoration-4 underline-offset-8">
          <strong>Telemedicine for mental health</strong>
        </p>
        <p className="text-base sm:text-lg md:text-xl lg:text-2xl max-w-screen-md mx-auto">
          At Blissful days, we believe that everyone deserves access to high
          quality behavioral healthcare. Our compassionate and dedicated staff
          is determined to provide each client with specialized services that
          addresses their needs and improve their quality of life. We work
          closely with our clients to develop a treatment plan that is tailored
          to their specific goals and needs.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-4 py-8 max-w-screen-xl mx-auto rounded-lg ">
        <div className="w-full md:w-1/2 flex items-stretch">
          <div className="w-full flex flex-col justify-center px-4 py-8">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4">
              We provide psychiatric evaluation and medication management for:
            </h2>
            <ul className="list-disc list-inside text-base sm:text-lg md:text-xl">
              {servicesList.map((service, index) => (
                <li key={index}>{service}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex items-stretch">
          <img
            className="w-full h-full object-cover rounded-md transition-transform duration-300 ease-in-out transform hover:scale-110"
            src={adhd1}
            alt="adhd1"
          />
        </div>
      </div>
      <Partner />
      <Footer />
    </>
  );
};

export default Anxiety;
